.react-calendar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: white;
  font-family: 'Proxima Nova', sans-serif;
  line-height: 1.125em;
}

.react-calendar__viewContainer {
  height: calc(100% - 61.5px);
}

.react-calendar--doubleView {
  width: 700px;

  .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;

    > * {
      width: 50%;
      margin: 0.5em;
    }
  }
}

.react-calendar {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:before, &:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled:hover {
      cursor: pointer;
    }
  }
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  border-bottom: 1px solid #e6ebf1;

  button {
    min-width: 44px;
    background: none;

    &.react-calendar__navigation__arrow {
      color: #d9d9d9;
      font-size: 24px;
    }

    &:disabled {
      background-color: #f0f0f0;
    }

    &:enabled {
      &:hover, &:focus {
        background-color: #e6e6e6;
      }
    }

    &.react-calendar__navigation__label {
      background-color: transparent;
      .react-calendar__navigation__label__labelText {
        color: black;
      }
    }
  }
}

.react-calendar__month-view {
  height: 100%;

  >div {
    height: 100%;
    align-items: center !important;

    >div {
      height: 100%;

      .react-calendar__month-view__days {
        height: calc(100% - 31px);
        justify-content: center;
        gap: 0.8% calc(14.2857% - 40px);
        margin-top: 1%;
        margin-bottom: 0.8%;

        .react-calendar__tile {
          flex: 0 0 40px !important;
          border-radius: 3px;
          height: 40px;
        }
      }
    }
  }
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;

  abbr[title] {
    text-decoration: none !important;
  }
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days {
  .react-calendar__tile.ifta_date {
      border: 2px solid #0091EA;
  }
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;

  &:disabled {
    // background-color: #f0f0f0;
    color: black;
  }

  &:enabled {
    &:hover, &:focus {
      background-color: #e6e6e6;
    }
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
  &:disabled {
    color: #757575;
  }
}

.react-calendar__tile--now {
  background: #ffff76;

  &:enabled {
    &:hover, &:focus {
      background: #ffffa9;
    }
  }
}

.react-calendar__tile--hasActive {
  background: #76baff;

  &:enabled {
    &:hover, &:focus {
      background: #a9d4ff;
    }
  }
}

.react-calendar__tile--active {
  background: #0091EA;
  color: white !important;

  &:enabled {
    &:hover, &:focus {
      background: #1087ff;
    }
  }
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
